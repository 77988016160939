@import "node_modules/bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter";
  min-height: 100vh;
  overflow: hidden;
}

.font-bold,
strong {
  font-weight: 600 !important;
  color: #181718 !important;
}

.primary-btn {
  height: 40px;
  background: #2520b6;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background: #2520b6;
  }
}

.cdk-global-scrollblock {
  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background: dimgray !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000000 !important;
    -webkit-border-radius: 1ex;
  }
}

.font-size-15 {
  font-size: 15px !important;
}

button:focus, button:focus:not(:focus-visible), button:focus-visible, .btn:focus {
  outline: 0;
  box-shadow: none;
}
