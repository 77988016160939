.card {
  box-shadow: none !important;
  margin-bottom: 15px !important;
}

.card-top {
  &-content {
    padding: 24px 24px 0 24px;
  }
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: #f6f6f6;
    margin: 16px 0;
  }
}

.card-bottom {
  margin: 0 !important;
  padding-top: 18px !important;
  border-top: 2px solid #f6f6f6 !important;
  width: 100% !important;
  height: 80px;
}

.card-form-content {
  background-color: #fff !important;
  min-height: calc(100vh - 180px) !important;
  padding-bottom: 80px !important;
}

@media only screen and (max-width: 576px) {
  .card-bottom-mobile {
    padding-top: 12px !important;
    height: 120px;
  }
  .card-form-mobile {
    padding-bottom: 120px !important;
  }
}

.btn-card-action {
  font-weight: 600 !important;
  min-height: 40px !important;
  padding: 10px 24px;
  min-width: 100px;
  font-size: 14px !important;
}

.page-title-text {
  font-size: 24px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  color: #181718 !important;
}

.page-title-icon {
  font-weight: 400 !important;
  width: 40px !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 10px;
  top: 0;
}

.form-control {
  min-height: 40px !important;
  font-size: 14px !important;

  &.with-action {
    position: relative;
    padding-right: 46px;

    &:focus + .form-control-action {
      z-index: 3;
    }
  }

  &-action {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    &-btn {
      display: flex;
    }
  }
}

.form-check-input:checked {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.text-primary {
  color: #2520b6 !important;
}

.text-success {
  color: #4daa57 !important;
}

.text-danger {
  color: #de3c4b !important;
}

.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-success {
  background-color: #4daa57 !important;
  border-color: #4daa57 !important;
}

.btn-danger {
  background-color: #de3c4b !important;
  border-color: #de3c4b !important;
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
  font-weight: 600 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  border-color: var(--bs-primary) !important;
  background-color: var(--bs-primary) !important;
  font-weight: 600 !important;
}

.btn-outline-success {
  color: #4daa57;
  border-color: #4daa57;
}

.btn-outline-danger {
  color: #de3c4b;
  border-color: #de3c4b;
}

.ng-select.ng-select-single .ng-select-container {
  min-height: 40px !important;
  font-size: 14px !important;
}

.col-form-label {
  font-size: 14px !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f46a6a !important;
}

.alert-danger {
  color: #de3c4b !important;
  background-color: #fceff1 !important;
  border-color: #f0a7af !important;
}
