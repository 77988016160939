@import "@fontsource/inter";

// variables from: apps/clearline-admin/src/assets/scss/_variables.scss
$primary: #2520b6;
$secondary: #74788d;
$component-active-color: #fff;
$component-active-bg: $primary;
$enable-shadows: false;
$input-focus-box-shadow: none;
$input-btn-focus-width: 0.15rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;


@import "app/shared/styles/custom/modal";
@import "app/shared/styles/custom/fontawesome-all";
@import "app/shared/styles/custom/cardcustom";
@import "app/shared/styles/global";

body {
  background: #171717;
}
